export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    Object.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "dark",
        themes: {
          dark: {
            dark: false,
            colors: {
              background: "#000",
              surface: "#000",
              rating: "#f4c74d",
              cardTitle: "#6a040f", // "#ffeeba",
              linkColor: "#004C9E",
              jackpot: "#007bff",
              iconColor: "#6a040f",
              toTop: "#f0f0f0",
              mainbg: "#e7e0c8",
              darkCard: "#ffeeba",
            },
          },
          light: {},
        },
      },
    });
  });
});

// export default defineNuxtPlugin(nuxtApp => {
//   nuxtApp.hook("vuetify:configuration", vuetify => {
//     vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
//     vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
//     Object.assign(vuetify.vuetifyOptions, {
//       theme: {
//         defaultTheme: "dark",
//         themes: {
//           light: {
//             dark: false,
//             colors: {
//               background: "#000",
//               rating: "#f4c74d",
//               // button: "#f95a00",
//               // breadcrumbs: "#FFFFFF",
//               cardTitle: "#ffeeba",
//               // linkColor: "#0066ff",
//               // jackpot: "#1950bd",
//               // // buttonTextColor: "#ffffff",
//               // // buttonBackground: "#3a96aa",
//               // iconColor: "#0066ff",
//               // toTop: "#fcac18",
//               // footer: "#212529",
//               // blueButton: "#1950bd",
//               mainbg: "#e7e0c8",
//             },
//           },
//           dark: {},
//         },
//       },
//     });
//   });
// });
